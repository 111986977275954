import HandyCollapse from "handy-collapse";
import "./hoa-footer.css";

class HoaFooter extends HTMLElement {
	public constructor() {
		super();

		const hc = new HandyCollapse({
			nameSpace: "hc-footer",
			isAnimation: false,
			closeOthers: false,
		});
	}
}

customElements.define("hoa-footer", HoaFooter);
